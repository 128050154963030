import React from 'react';
import Helmet from 'react-helmet';
import { useOutletContext, useParams } from 'react-router-dom';

import { hash } from '@amaui/utils';
import { AvailabilityCalendar, Button, Line, TextField, Type, useForm, useSnackbars } from '@amaui/ui-react';

import IconMaterialLockOpenRounded from '@amaui/icons-material-rounded-react/IconMaterialLockOpen';

import { LogService, StorageService, WorkDayService } from 'services';
import { NotFound } from 'ui';

const Element = () => {
  const params = useParams();
  const snackbars = useSnackbars();

  const { organization } = useOutletContext<any>();

  const [response, setResponse] = React.useState<any>();
  const [unlocked, setUnlocked] = React.useState(false);
  const [loaded, setLoaded] = React.useState(false);

  const form = useForm({
    values: {
      password: {
        name: 'Password',
        is: 'string',
        required: true
      }
    }
  });

  const refs = {
    form: React.useRef(form),
    storageKey: React.useRef<any>()
  };

  refs.form.current = form;

  const init = React.useCallback(async () => {
    const id = params.id_workDay;

    if (id) {
      const result = await WorkDayService.getPublic(id);

      if (result.status >= 400) {
        LogService.important(`Work days service error`, result.response);
      }
      else {
        const response = result.response.response;

        let unlocked_ = !response.protected;

        refs.storageKey.current = `workDay-${response.id}-password`;

        if (response.protected) {
          const userPassword = StorageService.get(refs.storageKey.current);

          if (userPassword !== undefined) {
            unlocked_ = response.password === userPassword;

            if (!unlocked_) StorageService.remove(refs.storageKey.current);
          }
        }

        setUnlocked(unlocked_);

        setResponse(response);
      }
    }

    setLoaded(true);
  }, [params]);

  React.useEffect(() => {
    // init
    init();
  }, []);

  const onUnlock = React.useCallback(() => {
    const passwordHashed = hash(refs.form.current.value.password);

    if (response.password === passwordHashed) {
      StorageService.add(refs.storageKey.current, passwordHashed);

      snackbars.add({
        primary: 'Unlocked',
        color: 'success'
      });

      setUnlocked(true);
    }
    else {
      snackbars.add({
        primary: 'Incorrect password',
        color: 'error'
      });
    }
  }, [response]);

  const withResponse = organization && response;

  return <>
    <Helmet>
      <title>{response?.name || 'Work day'} calendar</title>
    </Helmet>

    <Line
      gap={0}

      justify='unset'

      align='unset'

      flex

      fullWidth
    >
      {loaded && withResponse && !unlocked && (
        <Line
          align='center'

          fullWidth
        >
          <Line
            gap={0.5}

            align='center'

            fullWidth
          >
            <IconMaterialLockOpenRounded
              style={{
                marginBottom: 6
              }}
            />

            <Type
              version='t1'

              align='center'
            >
              Calendar is private
            </Type>

            <Type
              version='b2'

              align='center'
            >
              Provide a password to view the calendar
            </Type>
          </Line>

          <TextField
            name='Password'

            value={form.values['password'].value}

            error={form.values['password'].error}

            helperText={form.values['password'].error}

            onChange={(valueNew: any) => form.onChange('password', valueNew)}

            type='password'

            end={(
              <Button
                onClick={onUnlock}

                size='small'
              >
                Unlock
              </Button>
            )}

            size='small'
          />
        </Line>
      )}

      {loaded && withResponse && unlocked && (
        <AvailabilityCalendar
          name={response?.name}

          times={response?.times}

          meta
        />
      )}

      {loaded && !withResponse && (
        <NotFound
          name='Work day calendar not found'
        />
      )}
    </Line>
  </>;
};

export default Element;
