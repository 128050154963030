import { WorkDay } from '@amaui/api-utils';

import { BaseService } from './base';

class WorkDayService extends BaseService<WorkDay> {

  public constructor() {
    super('workDays');
  }

}

export default new WorkDayService();
