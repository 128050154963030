import React from 'react';
import Helmet from 'react-helmet';
import { useNavigate } from 'react-router-dom';

import { Image, Line, Tooltip, TopAppBar, Type, useMainProgress, useMediaQuery, useScroll } from '@amaui/ui-react';
import { AmauiTheme, Theme, classNames, style, useAmauiTheme } from '@amaui/style-react';
import { getMediaUrl } from '@amaui/themes';

const useStyle = style(theme => ({
  root: {
    paddingTop: '100px !important'
  },

  header: {
    top: '8px',
    left: '50%',
    transform: 'translateX(-50%)',
    cursor: 'default',
    userSelect: 'none',

    '&.amaui-TopAppBar-root': {
      width: `calc(100% - 48px)`,
      maxWidth: theme.breakpoints.values.lg,
      padding: 0,
      background: 'transparent',
      backdropFilter: 'blur(12px)',
      borderRadius: theme.methods.shape.radius.value('xxl', 'px'),
      transition: [
        theme.methods.transitions.make(['transform', 'left'], { duration: 'rg' }),
        theme.methods.transitions.make(['background', 'max-width', 'box-shadow'], { duration: 'complex' })
      ].join(', '),
      zIndex: theme.z_index.modal - 1
    },

    '& .amaui-TopAppBar-wrapper': {
      height: 'unset',
      padding: '12px 24px'
    }
  },

  header_not_top: {
    '&.amaui-TopAppBar-root': {
      maxWidth: theme.breakpoints.values.md,
      boxShadow: theme.shadows.values.default['2']
    }
  },

  header_down: {
    '&.amaui-TopAppBar-root': {
      transform: 'translate(-50%, calc(-100% - 16px))'
    }
  },

  logo: {
    '&.amaui-Image-root': {
      height: '44px',
      width: 'auto',

      '& path:nth-child(1)': {
        fill: theme.palette.light ? 'hsl(60deg 100% 49%)' : 'hsl(60deg 100% 15%)'
      }
    }
  },

  ...theme.classes(theme)
}), { name: 'amaui-app-public-Page' });

const Element = (props: any) => {
  const {
    title,

    organization,

    loaded,

    end,

    maxWidth,

    noHeader,

    style,

    children,

    ...other
  } = props;

  const { classes } = useStyle();

  const mainProgress = useMainProgress();

  const theme = useAmauiTheme();
  const navigate = useNavigate();

  const smallerScreen = useMediaQuery('(max-width: 1100px)');
  const mediumScreen = useMediaQuery('(max-width: 1540px)');

  const scrollNotTop = useScroll({ offset: 1 });
  const scrollDown = useScroll({ direction: 'down', offset: smallerScreen ? 40 : mediumScreen ? 100 : 200 });

  // logo
  const logo = organization?.settings?.logos?.logo_light || organization?.settings?.logos?.favicon_light || organization?.settings?.logos?.logo_dark || organization?.settings?.logos?.favicon_dark;

  // favicon 
  const favicon = (theme.palette.light ? organization?.settings?.logos?.favicon_light : organization?.settings?.logos?.favicon_dark) || organization?.settings?.logos?.favicon_light || organization?.settings?.logos?.favicon_dark;

  const faviconURL = favicon && getMediaUrl(favicon, 140);

  const {
    settings
  } = organization || {};

  const valueTheme = React.useMemo(() => {
    if (!settings) return {};

    return new AmauiTheme({
      palette: settings?.theme?.palette
    });
  }, [settings?.theme?.palette]);

  const toOrganization = React.useCallback(() => {
    if (organization) navigate(`/organizations/${organization.id}`);
  }, [organization]);

  React.useEffect(() => {
    !loaded ? mainProgress.start() : mainProgress.done();
  }, [loaded]);

  return <>
    <Helmet>
      <title>{title || organization?.name || 'Organization'}</title>

      {favicon ? <link rel='icon' type={favicon?.mime} sizes='16x16' href={faviconURL} /> : <link rel='icon' type='image/png' sizes='16x16' href={`/assets/favicon/${theme.palette.light ? 'light' : 'dark'}/favicon-16x16.png`} />}
      {favicon ? <link rel='icon' type={favicon?.mime} sizes='32x32' href={faviconURL} /> : <link rel='icon' type='image/png' sizes='32x32' href={`/assets/favicon/${theme.palette.light ? 'light' : 'dark'}/favicon-32x32.png`} />}
      <meta name='msapplication-TileColor' content='#ffffff' />
      <meta name='apple-mobile-web-app-status-bar-style' content={theme.palette.light ? theme.palette.color.primary.main : theme.palette.color.primary[40]} />
      <meta name='theme-color' content={theme.palette.light ? theme.palette.color.primary.main : theme.palette.color.primary[40]} />
    </Helmet>

    <Theme
      value={valueTheme}
    >
      <Line
        gap={0}

        align='unset'

        justify='unset'

        flex

        fullWidth

        className={classNames([
          classes.root,
          classes.page
        ])}

        style={{
          maxWidth,

          ...style
        }}

        {...other}
      >
        {/* header */}
        {loaded && !noHeader && (
          <TopAppBar
            start={logo ? (
              <Tooltip
                name={organization.name}
              >
                <Image
                  src={logo.urlSmall || logo.url}

                  alt={`${organization.name} logo`}

                  onClick={toOrganization}

                  className={classes.logo}
                />
              </Tooltip>
            ) : (
              <Type
                version='t2'

                color='default'

                onClick={toOrganization}
              >
                {organization.name}
              </Type>
            )}

            end={end}

            position='fixed'

            Component='header'

            className={classNames([
              classes.header,
              scrollNotTop && classes.header_not_top,
              scrollDown && classes.header_down
            ])}
          />
        )}

        <Line
          gap={0}

          align='unset'

          justify='unset'

          flex

          fullWidth

          Component='main'

          className={classes.main}
        >
          {loaded && children}
        </Line>
      </Line>
    </Theme>
  </>;
};

export default Element;
