import AmauiLog from '@amaui/log';

const amauiLog = new AmauiLog({
  arguments: {
    pre: [
      'amaui app'
    ]
  }
});

export default amauiLog;
