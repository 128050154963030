import React from 'react';

import { capitalize } from '@amaui/utils';
import { Form, Line, Link, Type } from '@amaui/ui-react';
import { AmauiDate, format } from '@amaui/date';

import { formats } from 'other';

export const versions = ['tels', 'emails', 'titles', 'urls', 'addresses', 'companies', 'birthdays', 'dates', 'custom'];

const toName = (value_: any, toCapitalize = true) => {
  let valueNew = value_;

  if (value_ === 'urls') valueNew = 'links';

  if (value_ === 'custom') valueNew = 'more';

  return toCapitalize ? capitalize(valueNew) : valueNew;
};

const getProperty = (item: any) => {
  let Element = <Type version='b2'>{item.value}</Type>

  const propsLink = {
    version: 'b2',
    target: 'blank'
  };

  if (item.version === 'email') Element = (
    <Link
      href={`mailto:${item.value}`}

      {...propsLink}
    >
      {item.value}
    </Link>
  );

  if (['mobile', 'tel', 'fax'].includes(item.version)) Element = (
    <Link
      href={`tel:${item.value}`}

      {...propsLink}
    >
      {item.value}
    </Link>
  );

  if (item.version === 'url') Element = (
    <Link
      href={item.value}

      {...propsLink}
    >
      {item.value}
    </Link>
  );

  if (['birthday', 'date'].includes(item.version)) Element = (
    <Type
      version='b2'
    >
      {format(new AmauiDate(item.value), formats.date)}
    </Type>
  );

  return (
    <Line
      key={item.id}
    >
      {React.cloneElement(Element)}
    </Line>
  );
};

const Element = (props: any) => {
  const {
    properties
  } = props;

  if (!Object.keys(properties || {}).length) return null;

  return (
    <Form
      gap={1}

      name='Contact information'

      accordion

      wrapper

      fullWidth

      AccordionMainProps={{
        style: {
          margin: '12px 0 0'
        }
      }}
    >
      {versions.filter(version => !!properties![version]?.length).map((version: string) => (
        <Form
          key={version}

          name={toName(version)}

          gap={0.5}

          size='small'

          wrapper
        >
          <Line
            gap={0.5}

            fullWidth
          >
            {properties![version]?.map((item: any) => getProperty(item))}
          </Line>
        </Form>
      ))}
    </Form>
  )
};

export default Element; 
