import { NotFound } from '@amaui/ui-react';

const Element = (props: any) => {

  return (
    <NotFound
      name='Not found'

      link='https://amaui.me'

      ButtonProps={{
        name: 'Visit amaui.me'
      }}

      {...props}
    />
  );
};

export default Element;
