import React from 'react';

import { is, textToInnerHTML } from '@amaui/utils';
import { Button, Slide } from '@amaui/ui-react';
import { IconButton, Line, Modal, ModalFooter, ModalHeader, ModalMain, ModalTitle, Tooltip } from '@amaui/ui-react';
import { classNames, style } from '@amaui/style-react';
import { TMethod } from '@amaui/models';

import IconMaterialKeyboardArrowDownRounded from '@amaui/icons-material-rounded-react/IconMaterialKeyboardArrowDown';

const useStyle = style(theme => ({
  root: {
    '&.amaui-Surface-root': {
      background: 'transparent'
    },

    '& .amaui-Modal-surface': {
      padding: 0
    }
  },

  modalHeader: {
    '&.amaui-ModalHeader-root': {
      paddingBottom: 8
    }
  },

  header: {
    position: 'relative',
    padding: '12px 12px 4px 24px',
    minHeight: 40,
    maxWidth: 1440,

    '&.amaui-ModalHeader-root': {
      padding: 0
    }
  },

  headerName: {
    wordBreak: 'break-word',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'normal'
  },

  main: {
    height: 0,
    overflow: 'hidden auto',
    padding: '4px 24px 24px',
    maxWidth: 1440
  },

  main_smaller: {
    position: 'relative',
    padding: '4px 24px 24px',
    minHeight: 84,
    maxHeight: 'calc(100vh - 240px)',
    maxWidth: 1440,
    overflow: 'hidden auto'
  },

  modalFooter: {
    '&.amaui-ModalFooter-root': {
      padding: 12
    }
  },

  surface: {
    '&.amaui-Surface-root': {
      background: theme.palette.color.primary[theme.palette.light ? 99 : 5]
    }
  },

  footer: {
    maxWidth: 1440
  }
}), { name: 'amaui-app-page-ModalObject' });

export interface IModalObject {
  id?: string;

  open?: boolean;

  version?: 'inline' | 'mid' | 'entire';

  name?: any;

  onClose?: TMethod;

  to?: string;

  route?: {
    previous: any;
    to: any;
  };

  [p: string]: any;
}

const Element = React.forwardRef((props: IModalObject, ref: any) => {
  const {
    open: open_,

    id,

    version = 'mid',

    name,

    to,

    route,

    smaller = true,

    onClose: onClose_,

    props: moreProps,

    className,

    children,

    ...other
  } = props;

  const { classes } = useStyle();

  const [open, setOpen] = React.useState<any>(false);

  const refs = {
    route: React.useRef(route)
  };

  refs.route.current = route;

  const onOpen = React.useCallback(() => {
    setOpen(true);
  }, []);

  const onClose = React.useCallback(() => {
    setOpen(false);

    // // update
    // // url
    // if (refs.route.current) {
    //   if (window.location.pathname !== refs.route.current.previous) window.history.pushState(undefined, '', refs.route.current.previous);
    // }

    if (is('function', onClose_)) onClose_!();
  }, [route, onClose_]);

  // React.useEffect(() => {
  //   // update
  //   // url
  //   if (open) {
  //     if (route) {
  //       if (window.location.pathname !== route.to) window.history.pushState(undefined, '', route.to);
  //     }
  //   }
  //   else {
  //     if (route) {
  //       if (window.location.pathname !== route.previous) window.history.pushState(undefined, '', route.previous);
  //     }
  //   }
  // }, [open, route]);

  React.useEffect(() => {
    if (open_ !== undefined && open_ !== open) setOpen(open_);
  }, [open_]);

  // modal
  return (
    <Modal
      ref={ref}

      open={open}

      minWidth='lg'

      fullScreen={version === 'entire'}

      onOpen={onOpen}

      onClose={onClose}

      TransitionComponent={Slide}

      SurfaceProps={{
        tonal: true,
        color: 'primary',

        className: classNames([
          classes.surface
        ])
      }}

      className={classNames([
        classes.root,
        className
      ])}

      {...other}
    >
      <ModalHeader
        gap={0}

        className={classes.modalHeader}
      >
        <Line
          direction='row'

          align='center'

          justify='space-between'

          fullWidth

          className={classNames([
            classes.header
          ])}
        >
          <ModalTitle
            className={classes.headerName}

            dangerouslySetInnerHTML={{
              __html: textToInnerHTML(name)
            }}
          />

          <Line
            gap={0}

            align='center'

            justify='center'

            direction='row'
          >
            <Tooltip
              name='Close'
            >
              <IconButton
                color='inherit'

                onClick={onClose}
              >
                <IconMaterialKeyboardArrowDownRounded />
              </IconButton>
            </Tooltip>
          </Line>
        </Line>
      </ModalHeader>

      <ModalMain
        align='center'

        justify='unset'

        fullWidth
      >
        <Line
          align='unset'

          justify='unset'

          flex

          fullWidth

          className={classNames([
            classes[`main${smaller ? '_smaller' : ''}`]
          ])}
        >
          {children}
        </Line>
      </ModalMain>

      <ModalFooter
        direction='row'

        align='center'

        justify='center'

        className={classNames([
          classes.modalFooter
        ])}
      >
        <Line
          direction='row'

          align='center'

          justify='space-between'

          fullWidth

          className={classNames([
            classes.footer
          ])}
        >
          <Button
            version='text'

            onClick={onClose}

            size='small'
          >
            Close
          </Button>
        </Line>
      </ModalFooter>
    </Modal>
  );
});

export default Element;
