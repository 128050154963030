import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import { Confirm, MainProgress, Snackbars } from '@amaui/ui-react';
import { Style, Theme, AmauiStyle, AmauiTheme, unit, prefix, sort, rtl, valueObject, makeClassName } from '@amaui/style-react';
import { IThemeValue } from '@amaui/style-react/ThemeProvider/Theme';

import * as serviceWorkerRegistration from './serviceWorkerRegistration';

import { FONT_FAMILY } from 'other';

import App from './App';

const valueStyle = new AmauiStyle();

valueStyle.plugins.add = [
  unit,
  prefix,
  sort,
  rtl,
  valueObject,
  {
    method: makeClassName,
    arguments: [
      {
        production: ['prod', 'production'].includes(process.env.NODE_ENV)
      }
    ]
  }
];

const valueTheme = new AmauiTheme({
  palette: {
    color: {
      amaui: {
        main: '#fafa00'
      }
    }
  },

  typography: {
    font_family: {
      primary: FONT_FAMILY.primary,
      secondary: FONT_FAMILY.secondary,
      tertiary: FONT_FAMILY.tertiary
    },

    values: {
      d1: { fontFamily: FONT_FAMILY.primary, fontWeight: 700 },

      d2: { fontFamily: FONT_FAMILY.primary, fontWeight: 700 },

      d3: { fontFamily: FONT_FAMILY.primary, fontWeight: 700 },

      h1: { fontFamily: FONT_FAMILY.primary, fontWeight: 700 },

      h2: { fontFamily: FONT_FAMILY.primary, fontWeight: 700 },

      h3: { fontFamily: FONT_FAMILY.primary, fontWeight: 700 },

      t1: { fontFamily: FONT_FAMILY.primary, fontWeight: 700 },

      t2: { fontFamily: FONT_FAMILY.primary, fontWeight: 700 },

      t3: { fontFamily: FONT_FAMILY.primary, fontWeight: 700 },

      l1: {
        fontFamily: FONT_FAMILY.secondary,
        fontWeight: 700
      },

      l2: {
        fontFamily: FONT_FAMILY.secondary,
        fontWeight: 700
      },

      l3: {
        fontFamily: FONT_FAMILY.secondary,
        fontWeight: 700
      },

      b1: { fontFamily: FONT_FAMILY.secondary },

      b2: { fontFamily: FONT_FAMILY.secondary },

      b3: { fontFamily: FONT_FAMILY.secondary },

      m1: { fontFamily: FONT_FAMILY.tertiary },

      m2: { fontFamily: FONT_FAMILY.tertiary },

      m3: { fontFamily: FONT_FAMILY.tertiary }
    }
  },

  ui: {
    elements: {
      amauiModal: {
        props: {
          default: {
            size: 'regular'
          }
        }
      },

      amauiModalMain: {
        props: {
          default: {
            align: 'center'
          }
        }
      },

      amauiButton: {
        props: {
          default: {
            color: 'inherit'
          }
        }
      },

      amauiFab: {
        props: {
          default: {
            color: 'primary'
          }
        }
      },

      amauiIconButton: {
        props: {
          default: {
            color: 'inherit'
          }
        }
      },

      amauiTooltip: {
        props: {
          default: {
            tonal: true,
            color: 'secondary'
          }
        }
      },

      amauiAutoComplete: {
        props: {
          default: {
            version: 'outlined'
          }
        }
      },

      amauiTextField: {
        props: {
          default: {
            version: 'outlined'
          }
        }
      },

      amauiSelect: {
        props: {
          default: {
            version: 'outlined'
          }
        }
      },

      amauiType: {
        props: {
          default: {
            color: 'inherit'
          }
        }
      },

      amauiDivider: {
        props: {
          default: {
            tonal: false
          }
        }
      }
    }
  },

  maxWidth: 1024,

  classes: (theme: IThemeValue) => ({
    page: {
      padding: 24,
      width: '100%',
      maxWidth: 1440,
      margin: '0 auto'
    },

    palette: {
      width: 17,
      height: 17,
      boxShadow: theme.palette.light ? '0px 1px 1px 0px rgba(0, 0, 0, 0.07), 0px 2px 1px -1px rgba(0, 0, 0, 0.04), 0px 1px 3px 0px rgba(0, 0, 0, 0.11)' : '0px 1px 1px 0px rgba(255, 255, 255, 0.21), 0px 2px 1px -1px rgba(255, 255, 255, 0.12), 0px 1px 3px 0px rgba(255, 255, 255, 0.40)',
      borderRadius: '50%',
      flex: '0 0 auto',
      transition: theme.methods.transitions.make('transform'),

      '& > *': {
        width: '100% !important',
        height: 'calc(100% + 12px) !important'
      },

      '&:active': {
        transform: 'scale(0.94)'
      }
    },

    logo: {
      '&.amaui-Image-root': {
        width: 'auto',
        height: 54,
        cursor: 'pointer',
        userSelect: 'none',
        transition: theme.methods.transitions.make('transform', { duration: 'xs' }),

        '&:active': {
          transform: 'scale(0.94)'
        }
      }
    },

    logo_dark: {
      fill: theme.palette.color.amaui[20]
    },

    externalLinks: {
      overflow: 'auto hidden'
    },

    aspectRatioYoutube: {
      aspectRatio: '16 / 9',
      width: 440
    },

    aspectRatioVimeo: {
      aspectRatio: '16 / 9',
      width: 440
    },

    aspectRatioInstagram: {
      aspectRatio: '3 / 5.7',
      width: 340
    },

    aspectRatioTiktok: {
      aspectRatio: '3 / 6.84',
      width: 324
    }
  })
});

const root = ReactDOM.createRoot(
  document.getElementById('amaui-root') as HTMLElement
);

root.render(
  <BrowserRouter>
    <Style
      value={valueStyle}
    >
      <Theme
        value={valueTheme}
      >
        <Snackbars
          position='bottom'

          alignment='center'
        >
          <MainProgress>
            <Confirm>
              <App />
            </Confirm>
          </MainProgress>
        </Snackbars>
      </Theme>
    </Style>
  </BrowserRouter>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister(); 
