import React from 'react';
import { Outlet, useParams } from 'react-router-dom';

import { Line, NotFound, useSnackbars } from '@amaui/ui-react';
import { style } from '@amaui/style-react';
import { IOrganization } from '@amaui/api-utils';

import { Defaults, Page } from 'ui';
import { OrganizationService } from 'services';
import { getErrorMessage } from 'other';

import { ReactComponent as IconLogo } from '../../assets/svg/logo.svg';

const useStyle = style(theme => ({
  root: {

  },

  ...theme.classes(theme)
}), { name: 'amaui-public-Organization-Root' });

const UI = (props: any) => {
  const { classes } = useStyle();

  const {
    organization,

    loaded,

    children
  } = props;

  return <>
    <Defaults />

    {loaded && organization && children}

    {loaded && !organization && (
      <NotFound
        start={(
          <Line
            align='center'

            style={{
              marginBottom: 14
            }}
          >
            <IconLogo
              className={classes.logo}
            />
          </Line>
        )}

        name={`Oops no page found 🫠`}

        link='https://amaui.me'

        ButtonProps={{
          name: 'Visit amaui.me'
        }}
      />
    )}
  </>;
};

const Element = () => {
  const params = useParams();
  const snackbars = useSnackbars();

  const [organization, setOrganization] = React.useState<IOrganization>();
  const [loaded, setLoaded] = React.useState(false);

  const init = React.useCallback(async () => {
    const { id } = params;

    if (!id) {
      setLoaded(true);

      return;
    }

    const result = await OrganizationService.getPublic(id);

    if (result.status >= 400) {
      snackbars.add({
        primary: getErrorMessage(result),
        color: 'error'
      });
    }
    else {
      const response = result.response.response;

      setOrganization(response);
    }

    setLoaded(true);
  }, [params]);

  React.useEffect(() => {
    // init 
    init();
  }, []);

  return (
    <Page
      title={organization?.name || 'Organization'}

      organization={organization}

      loaded={loaded}
    >
      <UI
        organization={organization}

        loaded={loaded}
      >
        <Outlet
          context={{
            organization,

            loaded
          }}
        />
      </UI>
    </Page>
  );
};

export default Element; 
