import React from 'react';

import { is, textToInnerHTML } from '@amaui/utils';
import { CalendarMonth, Chip, IconButton, Line, Select, Surface, Tooltip, Type } from '@amaui/ui-react';
import { classNames, style } from '@amaui/style-react';
import { AmauiDate, add, diff, format, is as isDate, months, remove, set } from '@amaui/date';

import IconMaterialKeyboardArrowLeftRounded from '@amaui/icons-material-rounded-react/IconMaterialKeyboardArrowLeft';
import IconMaterialKeyboardArrowRightRounded from '@amaui/icons-material-rounded-react/IconMaterialKeyboardArrowRight';

import { colorOptions, formats } from 'other';

const useStyle = style(theme => ({
  root: {

  },

  wrapperCalendarMonth: {
    overflow: 'auto hidden'
  },

  calendarMonth: {
    '&.amaui-CalendarMonth-root': {
      width: '100%',
      height: '100%',
      minHeight: 540,
      flex: '0 0 auto',
      minWidth: 1024,
      transition: theme.methods.transitions.make('opacity')
    },

    '& .amaui-CalendarMonth-weeks': {
      width: 'calc(100% - 2px)',
      height: 'calc(100% - 50px)',
      minHeight: 700,
      marginLeft: 1,
      gap: 0
    },

    '& .amaui-CalendarMonth-week': {
      flex: '1 1 auto'
    },

    '& .amaui-CalendarMonth-day': {
      height: 'unset',
      marginTop: -0.5,
      marginLeft: -0.5,
      border: `0.5px solid ${theme.palette.light ? '#dadada' : '#575757'}`
    },

    '& .amaui-CalendarMonth-day-out': {
      zIndex: -1
    },

    '& .amaui-CalendarMonth-day-name': {
      justifyContent: 'center',
      paddingInlineStart: 14,
      paddingTop: 14
    }
  },

  dayWrapper: {
    height: '100%'
  },

  dayWrapperToday: {
    background: theme.palette.background.primary[theme.palette.light ? 'tertiary' : 'quaternary']
  },

  day: {
    padding: 14,
    cursor: 'default',
    userSelect: 'none'
  },

  dayObjects: {
    height: 0,
    overflow: 'hidden auto',
    padding: '0 8px 12px',
    userSelect: 'none'
  },

  dayObject: {
    padding: '4px 8px',
    color: theme.methods.palette.color.text(theme.palette.background.secondary.quaternary),
    background: theme.palette.background.secondary.quaternary,
    borderRadius: 4,
    cursor: 'pointer',
    transition: theme.methods.transitions.make('transform'),

    '&:active': {
      transform: 'scale(0.97)'
    }
  },

  dayObjectName: {
    width: '100%'
  },

  month: {
    cursor: 'default',
    userSelect: 'none'
  },

  inPast: {
    opacity: 0.54
  }
}), { name: 'amaui-public-elements-Calendar' });

const Element = (props: any) => {
  const {
    values,

    dateProperty = 'starts_at',

    onOpen: onOpen_,

    className,

    ...other
  } = props;

  const { classes } = useStyle();

  const [value] = React.useState(new AmauiDate());
  const [date, setDate] = React.useState(new AmauiDate());

  const refs = {
    date: React.useRef(date),
    repeatCount: React.useRef<any>({})
  };

  refs.date.current = date;

  const onOpen = React.useCallback((item: any) => {
    if (is('function', onOpen_)) onOpen_(item);
  }, [onOpen_]);

  const onPreviousCalendar = React.useCallback(() => {
    const valueNew = remove(1, 'month', refs.date.current);

    setDate(new AmauiDate(valueNew));
  }, []);

  const onNextCalendar = React.useCallback(() => {
    const valueNew = add(1, 'month', refs.date.current);

    setDate(new AmauiDate(valueNew));
  }, []);

  const onToday = React.useCallback(() => {
    setDate(AmauiDate.amauiDate);
  }, []);

  const repeats = React.useCallback((day: AmauiDate, valueDate: number, repeat: any) => {
    if (!repeat?.active) return;

    let unit = repeat?.unit;
    let valueRepeat = repeat?.value;

    if (unit === 'day') unit = 'days';

    if (unit === 'week') {
      unit = 'days';

      valueRepeat *= 7;
    }

    if (unit === 'month') unit = 'months';

    let difference = diff(day, date, unit);

    let repeating = false;

    if (['day', 'week'].includes(repeat?.unit)) {
      if (
        repeat.unit === 'week' &&
        !!repeat?.weekdays?.length
      ) {
        difference = diff(day, date.dayWeek === 0 ? add(-1, 'week', date) : date, 'weeks');

        if (day.dayWeek === 0) difference -= 1;

        const modulus = difference % repeat?.value;

        repeating = isDate(day, 'after or same', date) && !modulus && repeat.weekdays?.includes(day.dayWeek);
      }
      else {
        const modulus = difference % valueRepeat;

        repeating = isDate(day, 'after or same', date) && !modulus;
      }
    }

    if (['month'].includes(repeat?.unit)) {
      const modulus = difference % valueRepeat;

      const monthDate = add(difference, 'month', date);

      repeating = (monthDate.year === day.year && monthDate.dayYear === day.dayYear) && !modulus;
    }

    if (['year'].includes(repeat?.unit)) {
      const modulus = difference % valueRepeat;

      const yearDate = add(difference, 'year', date);

      repeating = isDate(day, 'after', date) && (yearDate.year === day.year && yearDate.dayYear === day.dayYear) && !modulus;
    }

    if (
      (repeat?.skip_weekends) &&
      [0, 6].includes(day.dayWeek)
    ) repeating = false;

    return repeating;
  }, []);

  const renderDay = React.useCallback((valueDay: AmauiDate, propsDay: any, day: any, outside: boolean) => {
    // values for the day 
    const formated = format(valueDay, formats.date);

    const valuesDay = values?.filter((item: any) => {
      let result = repeats(valueDay, item[dateProperty], item.repeat);

      const itemFormated = format(new AmauiDate(item[dateProperty]), formats.date);

      if (!item.repeat.active) return formated === itemFormated;

      if (item.repeat?.ends?.active) {
        // date 
        if (item.repeat.ends.version === 'date') {
          const endsDate = new AmauiDate(item.repeat.ends.value);

          result = result && (
            (valueDay.year < endsDate.year) ||
            (
              valueDay.year === endsDate.year &&
              (
                (valueDay.month < endsDate.month) ||
                (
                  valueDay.month === endsDate.month &&
                  valueDay.dayYear <= endsDate.dayYear
                )
              )
            )
          );
        }
      }

      if (result) {
        if (!refs.repeatCount.current[item.id]) refs.repeatCount.current[item.id] = [];

        if (!refs.repeatCount.current[item.id].includes(formated)) refs.repeatCount.current[item.id].push(formated);
      }

      return result;
    }).filter((item: any) => {
      let result = true;

      const itemFormated = format(new AmauiDate(item[dateProperty]), formats.date);

      if (!item.repeat.active) return formated === itemFormated;

      if (item.repeat?.ends?.active) {
        const indexRepeated = refs.repeatCount.current[item.id]?.indexOf(formated);

        // count 
        if (item.repeat.ends.version === 'count') result = result && item.repeat.ends.value >= ((indexRepeated === -1 ? 0 : indexRepeated) + 1);
      }

      return !item.repeat.ends?.active || result;
    });

    const now = new AmauiDate();

    return (
      <Line
        gap={0}

        direction='column'

        align='unset'

        justify='unset'

        flex

        fullWidth

        className={classNames([
          classes.dayWrapper,
          day.today && classes.dayWrapperToday
        ])}
      >
        <Line
          direction='row'

          justify='flex-start'

          align='center'

          fullWidth

          className={classes.day}
        >
          <Type
            version='b3'
          >
            {format(valueDay, 'DD')}
          </Type>
        </Line>

        <Line
          gap={0.5}

          flex

          fullWidth

          className={classes.dayObjects}
        >
          {valuesDay?.map((item: any, index: number) => {
            const itemColor = colorOptions[item.color];

            const itemDate = new AmauiDate(item[dateProperty]);

            const inPast = itemDate.year < now.year || (itemDate.year === now.year && (itemDate.month < now.month || (itemDate.month === now.month && itemDate.dayYear < now.dayYear)));

            return (
              <Surface
                key={index}

                onClick={() => onOpen(item)}

                color={itemColor.color || 'primary'}

                fullWidth

                Component={Line}

                className={classNames([
                  classes.dayObject,
                  inPast && classes.inPast
                ])}
              >
                <Type
                  version='b3'

                  className={classes.dayObjectName}
                >
                  {textToInnerHTML(item.name)}{item.status ? `, ${item.status}` : ''}
                </Type>
              </Surface>
            );
          })}
        </Line>
      </Line>
    );
  }, [onOpen, values, dateProperty]);

  const renderDayName = React.useCallback((order: number) => {
    const values: any = {
      1: 'Mon',
      2: 'Tue',
      3: 'Wed',
      4: 'Thu',
      5: 'Fri',
      6: 'Sat',
      7: 'Sun'
    };

    return values[order];
  }, []);

  const onChangeMonth = React.useCallback((valueNew: any) => setDate(set(valueNew, 'month', refs.date.current)), []);

  const onChangeYear = React.useCallback((valueNew: any) => setDate(set(valueNew, 'year', refs.date.current)), []);

  const monthOptions = React.useMemo(() => {
    return months.map((item, index: number) => ({
      name: item,
      value: index
    }));
  }, [months]);

  const yearOptions = React.useMemo(() => {
    const values = [];

    for (let i = 1970; i <= 2070; i++) values.push({ name: i, value: i });

    return values;
  }, []);

  const elementProps: any = {

  };

  return (
    <Line
      gap={0}

      direction='column'

      justify='unset'

      align='unset'

      flex

      fullWidth

      className={classNames([
        className,
        classes.root
      ])}

      {...other}
    >
      <Line
        direction='row'

        align='center'

        justify='space-between'

        fullWidth

        style={{
          marginBottom: 8
        }}
      >
        <Tooltip
          name='Previous month'
        >
          <IconButton
            onClick={onPreviousCalendar}

            {...elementProps}
          >
            <IconMaterialKeyboardArrowLeftRounded />
          </IconButton>
        </Tooltip>

        <Line
          gap={1}

          align='center'

          fullWidth
        >
          <Line
            gap={1}

            align='center'

            fullWidth
          >
            <Line
              gap={1}

              direction={{
                default: 'row',
                xxs: 'column',
                xs: 'column',
                sm: 'column'
              }}

              align='center'
            >
              <Select
                name='Month'

                value={monthOptions.find(item => item.value === date.month - 1)?.value}

                onChange={onChangeMonth}

                options={monthOptions as any}

                size='small'
              />

              <Select
                name='Year'

                value={yearOptions.find(item => item.value === date.year)?.value}

                onChange={onChangeYear}

                options={yearOptions as any}

                size='small'
              />
            </Line>
          </Line>

          <Line
            gap={1}

            direction='row'

            align='center'

            className={classes.actions}
          >
            <Chip
              onClick={onToday}

              selected={format(date, formats.date) === format(AmauiDate.amauiDate, formats.date)}

              size='small'

              {...elementProps}
            >
              Today
            </Chip>
          </Line>
        </Line>

        <Tooltip
          name='Next month'
        >
          <IconButton
            onClick={onNextCalendar}

            {...elementProps}
          >
            <IconMaterialKeyboardArrowRightRounded />
          </IconButton>
        </Tooltip>
      </Line>

      <Line
        flex

        fullWidth

        className={classes.wrapperCalendarMonth}
      >
        <CalendarMonth
          value={value as any}

          calendar={date as any}

          renderDay={renderDay as any}

          renderDayName={renderDayName}

          size='large'

          dayNamesFull

          noTransition

          outside={false}

          className={classes.calendarMonth}
        />
      </Line>
    </Line>
  );
};

export default Element;
