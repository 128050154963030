import React from 'react';

import { cleanValue } from '@amaui/utils';
import { colors } from '@amaui/style-react';
import { IResponse } from '@amaui/sdk/other';

import { ErrorBoundary } from 'ui';

export const FONT_FAMILY = {
  primary: ['Montserrat', 'Helvetica', 'Helvetica Neue', '-apple-system', 'BlinkMacSystemFont', '"Segoe UI"', 'sans-serif'].join(', '),
  secondary: ['DM Sans', 'Helvetica', 'Helvetica Neue', '-apple-system', 'BlinkMacSystemFont', 'Segoe UI', 'sans-serif'].join(', '),
  tertiary: ['Roboto Mono', 'monospace'].join(', ')
};

export const getErrorMessage = (result: IResponse) => result?.response?.meta?.message || 'Error occured';

export const PAGINATION_LIMIT = 14;

export const RouteWrapper = React.forwardRef((props: any, ref: any) => {
  const {
    children,

    ...other
  } = props;

  return (
    <ErrorBoundary
      ref={ref}

      {...other}
    >
      {children}
    </ErrorBoundary>
  );
});

export const wrapper = (Element: any) => (
  <RouteWrapper
    fallback
  >
    <Element />
  </RouteWrapper>
);

export const formats = {
  entire: 'DD MMM, YYYY [at] h:mm a',
  date: 'DD MMM, YYYY',
  time: 'h:mm a'
};

export const geolocationPositionOptions = {
  enableHighAccuracy: true,
  timeout: 14e3,
  maximumAge: 4e3
};

export const getCurrentPosition: () => Promise<GeolocationPosition> = () => new Promise(resolve => {
  navigator.geolocation.getCurrentPosition(resolve as any, () => resolve(false as any), geolocationPositionOptions);
});

export const colorOptions: any = {};

Object.keys(colors).forEach(item => {
  const color = (colors[item as 'green'])['A200'] || (colors[item as 'green'])['400'] || colors[item as 'white'];

  colorOptions[item] = {
    name: cleanValue(item, { capitalize: true }),
    color,
    value: item
  };
});

export const dates = ['added_at', 'updated_at', 'received_at', 'removed_at', 'resolved_at', 'starts_at', 'ends_at'];

export const booleans = ['active', 'archived', 'pinned', 'private', 'default', 'main', 'amaui', 'google', 'resolved'];

export const references = ['contacts', 'contact_groups', 'users'];
