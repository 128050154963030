import React from 'react';
import Helmet from 'react-helmet';
import { useOutletContext } from 'react-router-dom';

import { textToInnerHTML } from '@amaui/utils';
import { Avatar, Chip, Line, Type } from '@amaui/ui-react';

import { PropertiesContact } from 'ui';

export const versions = ['tels', 'emails', 'titles', 'urls', 'addresses', 'companies', 'birthdays', 'dates', 'custom'];

const Element = () => {
  const { organization, loaded } = useOutletContext<any>();

  const {
    location,
    properties
  } = organization || {};

  const onOpenMap = React.useCallback(() => {
    if (location) {
      const coordinates: any = location.coordinates;

      window.open(`https://maps.google.com/?q=${coordinates.join(',')}`);
    }
  }, [location]);

  if (!loaded) return null;

  return <>
    <Helmet>
      <title>{organization?.name || 'Organization'}</title>
    </Helmet>

    <Line
      gap={3}

      align='center'

      justify='flex-start'

      fullWidth
    >
      <Line
        gap={3}

        align='center'

        justify='flex-start'

        flex

        fullWidth
      >
        <Line
          gap={1.4}

          align='center'

          justify='flex-start'

          flex

          fullWidth
        >
          <Avatar
            size='large'
          >
            {organization.name?.slice(0, 2)}
          </Avatar>

          <Line
            gap={0.5}

            align='center'

            justify='flex-start'

            flex

            fullWidth
          >
            <Type
              version='h3'

              align='center'
            >
              {organization.name || organization.short_name}
            </Type>

            {location && (
              <Line
                gap={1}

                align='center'

                fullWidth
              >
                <Type
                  version='b3'
                >
                  Location: {location.name}
                </Type>

                <Chip
                  tonal

                  color='primary'

                  onClick={onOpenMap}

                  size='small'
                >
                  Open map
                </Chip>
              </Line>
            )}
          </Line>
        </Line>
      </Line>

      <Line
        gap={1}

        align='center'

        justify='center'

        fullWidth
      >
        {organization.description && (
          <Type
            version='b2'

            dangerouslySetInnerHTML={{
              __html: textToInnerHTML(organization.description)
            }}
          />
        )}

        <PropertiesContact
          properties={properties}
        />
      </Line>
    </Line>
  </>;
};

export default Element; 
