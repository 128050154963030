import { UrlShortener } from '@amaui/api-utils';

import { IAdditional, IResponse, IUrlShortenerAnalytic } from '@amaui/sdk/other';

import { BaseService } from './base';
import AmauiService from './amaui';

class UrlShortenerService extends BaseService<UrlShortener> {

  public constructor() {
    super('urlShorteners');
  }

  public async analytic(body: IUrlShortenerAnalytic, options?: IAdditional): Promise<IResponse> {
    return AmauiService.sdk[this.route as 'urlShorteners']?.analytic(body, options);
  }

}

export default new UrlShortenerService();
