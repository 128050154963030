import { Line, NotFound } from '@amaui/ui-react';
import { style } from '@amaui/style-react';

import { ReactComponent as IconLogo } from '../assets/svg/logo.svg';

const useStyle = style(theme => ({
  root: {

  },

  logo: {
    width: 'auto',
    height: 54
  }
}), { name: 'amaui-app-public-Root' });

const Root = () => {
  const { classes } = useStyle();

  return (
    <Line
      align='center'

      justify='center'

      flex

      fullWidth
    >
      <NotFound
        start={(
          <Line
            align='center'

            style={{
              marginBottom: 14
            }}
          >
            <IconLogo
              className={classes.logo}
            />
          </Line>
        )}

        name={`Oops no page found 🫠`}

        link='https://amaui.me'

        ButtonProps={{
          name: 'Visit amaui.me'
        }}
      />
    </Line>
  );
};

export default Root; 
