import { Line } from '@amaui/ui-react';
import { style } from '@amaui/style-react';

const useStyle = style(theme => ({
  '@p': {
    body: {
      fontFamily: theme.typography.font_family.secondary,
      color: theme.palette.text.default.primary,
      backgroundColor: theme.palette.light ? theme.palette.color.primary[99] : theme.palette.color.primary[5]
    },

    a: {
      color: theme.palette.color.primary[30]
    }
  }
}), { name: 'ui-Defaults' });

const Element = () => {
  useStyle();

  return (
    <Line />
  );
};

export default Element;
