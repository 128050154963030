import { IOptionsRequest } from '@amaui/request/AmauiRequest';
import AmauiSDK from '@amaui/sdk';
import { IAmauiSDKOptions } from '@amaui/sdk/other';
import AmauiSub from '@amaui/subscription';
import { merge } from '@amaui/utils';

import config from 'config';
import { StorageService } from 'services';

class AmauiService {
  public initial = new AmauiSub();
  public sdk: AmauiSDK = new AmauiSDK({
    amaui_token: config.value.amaui_token,

    apis: {
      api: {
        url: config.value.apis.api.url
      },
      api_media_read: {
        url: config.value.apis.api_media_read.url
      },
      api_media_write: {
        url: config.value.apis.api_media_write.url
      }
    }
  });

  public init(options_: IAmauiSDKOptions = {}): AmauiSDK {
    const options = merge(options_, {
      amaui_token: config.value.amaui_token,

      apis: {
        api: {
          url: config.value.apis.api.url
        },
        api_media_read: {
          url: config.value.apis.api_media_read.url
        },
        api_media_write: {
          url: config.value.apis.api_media_write.url
        }
      }
    });

    this.sdk = new AmauiSDK(options);

    this.sdk.requestService.amauiRequest.interceptors.request.pre.subscribe((value: IOptionsRequest) => {
      const token = StorageService.get('token');

      if (token) value.request!.headers!['Authorization'] = `amaui ${token}`;

      const deviceID = StorageService.get('device-id');

      if (deviceID) value.request!.headers!['device-id'] = deviceID;

      const employee = StorageService.get('employee');

      if (employee) value.request!.headers!['employee'] = employee?.id || employee?._id || employee;

      const customer = StorageService.get('customer');

      if (customer) value.request!.headers!['customer'] = customer?.id || customer?._id || customer;

      const website = StorageService.get('website');

      if (website) {
        value.request!.headers!['website-id'] = website?.id || website?._id;
      }
    });

    return this.sdk;
  }
}

export default new AmauiService();
