import { Location } from '@amaui/api-utils';

import { BaseService } from './base';

class LocationService extends BaseService<Location> {

  public constructor() {
    super('locations');
  }

}

export default new LocationService();
